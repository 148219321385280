import Filters from "~/shared/filters";
import { SectionHeader } from "~/components/typography";
import JobPosts from "~/components/job_posts";
import { type Department } from "~/types/jben/department";
import { type Office } from "~/types/jben/office";
import type { BoardLayout, FilterConfiguration } from "~/types/jben/board_configuration";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { isClient } from "~/utils/env_check";
import config from "~/config.server";
import Pagination, { links as paginationLinks } from "./pagination";
import type { CustomField } from "~/types/jben/custom_field";

export const links = () => [...paginationLinks()];

interface Props {
  jobPosts: any;
  departments: Department[];
  offices: Office[];
  customFields: CustomField[];
  departmentIds?: string[];
  officeIds?: string[];
  customFieldFilters?: Record<string, string[]>;
  keyword?: string;
  filterConfiguration: FilterConfiguration;
  boardLayoutConfiguration: BoardLayout;
  internalBoard: boolean;
  filtered?: boolean;
  outsideLabel: boolean;
}

export const MAX_PER_PAGE = isClient()
  ? window.ENV.MAX_POSTS_PER_PAGE
  : (config.get("max_posts_per_page") as number);

const BoardContent = ({
  jobPosts,
  departments,
  offices,
  customFields,
  departmentIds,
  officeIds,
  customFieldFilters,
  keyword,
  filterConfiguration,
  boardLayoutConfiguration,
  internalBoard,
  filtered,
}: Props) => {
  const { t } = useTranslation("board");

  const classes = classNames({
    index__side_by_side: boardLayoutConfiguration === "side_by_side",
    index__main: true,
  });

  const rightSideClasses = classNames({
    padding: true,
    "index__side_by_side--right": boardLayoutConfiguration === "side_by_side",
  });

  return (
    <div className={classes}>
      <Filters
        departments={departments}
        offices={offices}
        customFields={customFields}
        departmentIds={departmentIds}
        officeIds={officeIds}
        customFieldFilters={customFieldFilters}
        keyword={keyword}
        config={filterConfiguration}
        boardLayoutConfiguration={boardLayoutConfiguration}
      />

      <div className={rightSideClasses}>
        <SectionHeader as="h2" large>
          {t("total_jobs", { count: jobPosts.total })}
        </SectionHeader>
        <JobPosts jobPosts={jobPosts.data} filtered={filtered} internalBoard={internalBoard} />
        {jobPosts.total > MAX_PER_PAGE && (
          <div className="pagination-wrapper">
            <Pagination currentPage={jobPosts.page} totalPages={jobPosts.total_pages} center />
          </div>
        )}
      </div>
    </div>
  );
};

export default BoardContent;
